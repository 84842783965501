<template>
  <div class="search-result">
    <MainHeader />
    <CommonTop :keywords="params.keywords" />
    <div class="page-content-wrap">
      <div v-if="total === 0" class="nodata">
        <div class="nodata-content">
          <div class="cover">
            <img src="../../assets/images/common/nodata.png" alt="">
          </div>
          <div class="tips">
            <div class="tips-title">抱歉，没有找到相关的产品</div>
            <div>
              建议您：<br />
              1、适当减少筛选条件<br />
              2、尝试其他关键字
            </div>
          </div>
        </div>
      </div>
      <div v-else class="list">
        <div @click="jumpGoodsDetail(item)" v-for="(item, index) in listData" :key="index" class="item">
          <div class="cover">
            <img :src="item.cover" alt="">
          </div>
          <div class="name">{{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MainHeader from '@/components/header/main.vue'
import CommonTop from '@/components/commonTop/index.vue'
export default {
  components: {
    MainHeader,
    CommonTop
  },
  data () {
    return {
      params: {
        page: 1,
        per_page: 30,
        keywords: ''
      },
      listData: [],
      total: null
    }
  },
  watch: {
    $route (to) {
      this.params.page = 1
      this.params.keywords = to.query.keywords
      this.getGoodsList()
    }
  },
  created () {
    this.params.keywords = this.$route.query.keywords
    this.getGoodsList()
  },
  methods: {
    // 跳转详情页
    jumpGoodsDetail (item) {
      this.$router.push({ path: '/goods/detail', query: { id: item.id } })
    },
    async getGoodsList () {
      const res = await this.$http.get('/api/goods', this.params)
      this.total = res.total
      this.listData = res.data
    }
  }
}
</script>
<style lang="scss" scoped>
.nodata {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  .tips {
    line-height: 1.6;
  }
  .tips-title {
    font-weight: bold;
    font-size: 18px;
  }
  .nodata-content {
    display: flex;
    .cover {
      img {
        width: 100px;
        margin-right: 20px;
      }
    }
  }
}
.search-result {
  background-color: #F5F5F5;
}
.page-content-wrap {
  padding: 20px 0;
  min-height: 500px;
  .list {
    display: flex;
    flex-wrap: wrap;
    .item {
      margin-right: 15px;
      margin-top: 15px;
      width: 228px;
      overflow: hidden;
      border-radius: 5px;
      padding-bottom: 20px;
      padding-top: 20px;
      cursor: pointer;
      transition: all .2s linear;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:hover {
        box-shadow: 0 15px 30px rgba(0,0,0,.1);
        transform: translate3d(0,-2px,0);
        .cover {
          img {
            transform: scale(1.2);
          }
        }
      }
      &:nth-child(5n) {
        margin-right: 0;
      }
      &:nth-child(-n + 5) {
        margin-top: 0;
      }
      background-color: #ffffff;
      .cover {
        width: 168px;
        height: 168px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img {
          max-width: 100%;
          max-height: 100%;
          transition: all .3s;
        }
      }
      .name {
        color: #333;
        text-align: center;
        margin-top: 30px;
        height: 32px;
        line-height: 32px;
        padding: 0 20px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
